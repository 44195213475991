import React,{useState,useEffect} from 'react'
import AdminTemplate from '../Components/Template/AdminTemplate'
import { useCreateQuoteMutation, useCreateWordMutation, useDeleteQuoteMutation, useDeleteWordMutation, useGetAllQuotesQuery, useGetAllWordsQuery, useUpdateQuoteMutation, useUpdateWordMutation } from '../Services/modules/otherinfo'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable';
import { Container, Grid, Paper } from '@mui/material';
import SubmitButton from '../Components/InputFields/SubmitButton';
import TextBox from '../Components/InputFields/TextBox';
import { Button, Space } from 'antd';
import { useCreateLiveSeminarMutation, useDeleteLiveSeminarsMutation, useGetAllLiveSeminarsQuery, useUpdateLiveSeminarsMutation } from '../Services/modules/liveSeminar';
import deleteSeminar from '../Services/modules/liveSeminar/deleteSeminar';
import { formatDateString, handleKeyDownDate } from '../hooks/formateDate';

function LiveSeminar() {
 
   
const{data,isSuccess,isLoading,error}=useGetAllLiveSeminarsQuery()
const [CreateLiveSeminar, { data:createData, isSuccess:createSuccess, isLoading:createLoading, isError:createError }] = useCreateLiveSeminarMutation();
const [UpdateLiveSeminar, { data:updateData, isSuccess:updateSuccess, isLoading:updateLoading, isError:updateError }] = useUpdateLiveSeminarsMutation();
const [DeleteLiveSeminar, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteError }] = useDeleteLiveSeminarsMutation();

const[isupdate,setIsUpdate]=useState()
const[isDeleteAlert,setDeleteAlert]=useState(false)
const[allSeminars,setAllSeminars]=useState([])

const[seminar_id,setSeminarId]=useState()
const[seminarName,setSeminarName]=useState("")
const[seminarAddress,setSeminarAddress]=useState("")
const[seminarCity,setSeminarCity]=useState("")
const[totalSeates,setTotalSeates]=useState("")
const[seminarDate,setSeminarDate]=useState("")
const[seminarTime,setSeminarTime]=useState("")
const[seminarFees,setSeminarFees]=useState("")
const[lastDateForBooking,setLastDateForBooking]=useState("")



  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info


const[totalCount,setTotalCount]=useState(0)

const columns = [ 
   
    { 
    key: "seminarName", 
    title: "Name", 
    dataIndex: "seminarName", 
    }, 
    { 
        key: "seminarAddress", 
        title: "Address", 
        dataIndex: "seminarAddress", 
        }, 
        { 
          key: "totalSeates", 
          title: "Seates", 
          dataIndex: "totalSeates", 
          }, 
          { 
            key: "seminarDate", 
            title: "Date", 
            dataIndex: "seminarDate", 
            }, 
            { 
              key: "seminarTime", 
              title: "Time", 
              dataIndex: "seminarTime", 
              }, 
              { 
                key: "seminarFees", 
                title: "Fees", 
                dataIndex: "seminarFees", 
                }, 
                { 
                  key: "lastDateForBooking", 
                  title: "Last Date For Booking", 
                  dataIndex: "lastDateForBooking", 
                  }, 
   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>openDeleteAlert(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>  
          <a onClick={()=>updateSeminar(record)} style={{color:"green"}}>
          <EditIcon style={{fontSize:'medium'}}/> 
          </a>     
         
        </Space>
      ),
    },
    
    ] 
useEffect(()=>{

    if(isSuccess)
    {
        console.log("words data",data)
        setAllSeminars(data.data)
        setTotalCount(data.totalRows)
    }
},[data])
useEffect(()=>{
    console.log("createData",createData)
    if(createSuccess)
    {            
        toast.success(createData.message)
        setSeminarName("")
        setSeminarId("")
        setSeminarAddress("")
        setSeminarCity("")
        setSeminarDate("")
        setSeminarTime("")
        setLastDateForBooking("")
        setSeminarFees("")
        setTotalSeates("")

    }
    else
    {       
     //   toast.success("Something went Wrong")
     setSeminarName("")
     setSeminarId("")
     setSeminarAddress("")
     setSeminarCity("")
     setSeminarDate("")
     setSeminarTime("")
     setLastDateForBooking("")
     setSeminarFees("")
     setTotalSeates("")

    }
},[createData])
useEffect(()=>{

    if(updateSuccess)
    {
      setSeminarName("")
        setSeminarId("")
        setSeminarAddress("")
        setSeminarCity("")
        setSeminarDate("")
        setSeminarTime("")
        setLastDateForBooking("")
        setSeminarFees("")
        setTotalSeates("")
         
        setIsUpdate(false)  
        toast.success(updateData.message)
    }
    else
    {
      setSeminarName("")
        setSeminarId("")
        setSeminarAddress("")
        setSeminarCity("")
        setSeminarDate("")
        setSeminarTime("")
        setLastDateForBooking("")
        setSeminarFees("")
        setTotalSeates("")

        setIsUpdate(false)        
       // toast.success("Something went Wrongg")
    }
},[updateData])
useEffect(()=>{
    if(deleteSuccess)
    {
      setSeminarName("")
        setSeminarId("")
        setSeminarAddress("")
        setSeminarCity("")
        setSeminarDate("")
        setSeminarTime("")
        setLastDateForBooking("")
        setSeminarFees("")
        setTotalSeates("")

        setDeleteAlert(false)
        toast.success(deleteData.message)
    }
    else
    {
        setDeleteAlert(false)
        setSeminarName("")
        setSeminarId("")
        setSeminarAddress("")
        setSeminarCity("")
        setSeminarDate("")
        setSeminarTime("")
        setLastDateForBooking("")
        setSeminarFees("")
        setTotalSeates("")
    }
},[deleteData])
const openDeleteAlert=(record)=>{
    setSeminarId(record._id)
    setDeleteAlert(true)
}
const deleteSeminar=()=>{
    console.log("In delete quote")
    deleteSeminar({seminar_id:seminar_id})
}
const updateSeminar=(record)=>{
    setSeminarName(record.seminarName)   
    setSeminarId(record._id)

        setSeminarAddress(record.seminarAddress)
        setSeminarCity(record.seminarCity)
        setSeminarDate(record.seminarDate)
        setSeminarTime(record.seminarTime)
        setLastDateForBooking(record.lastDateForBooking)
        setSeminarFees(record.seminarFees)
        setTotalSeates(record.totalSeates)
        setIsUpdate(true)
}
const onSubmit=()=>{
    if(isupdate)
    {
        UpdateLiveSeminar(
          {
          seminarName:seminarName,
          seminar_id:seminar_id,
          seminarAddress:seminarAddress,
          seminarCity:seminarCity,
          seminarFees:seminarFees,
          lastDateForBooking:lastDateForBooking,
          seminarTime:seminarTime,
          totalSeates:totalSeates
        }
        )
    }
    else
    {
      CreateLiveSeminar(
        {
          seminarName:seminarName,
          seminar_id:seminar_id,
          seminarAddress:seminarAddress,
          seminarCity:seminarCity,
          seminarFees:seminarFees,
          lastDateForBooking:lastDateForBooking,
          seminarTime:seminarTime,
          totalSeates:totalSeates
      }
    )
    }
}
const handlePageChange = (pagination) => {
  setPageInfo({
      current: pagination.current,
      pageSize: pagination.pageSize,
  });
};
// function handleDate(e) {
   
//   var value=e.target.value
//   const formattedDate = formatDateString(value);
//   setSeminarDate(formattedDate)
// }
function handleDate(e, setDate) {
  const formattedDate = formatDateString(e.target.value);
  setDate(formattedDate);
};
    const liveSeminarScreen=()=>{
        return(
            <>
            <Container alignItems="center" sx={{mt:5}}>

      <ToastContainer />

            <Dialog
        open={isDeleteAlert}
       onClose={()=>setDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={()=>setDeleteAlert(false)}
          >No</Button>
          <Button onClick={()=>deleteSeminar()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <TextBox             
             label={"Add Seminar Name"}
             id={"seminarName"}
             name={"seminarName"}
             autoFocus={true}                         
            value={seminarName}  
            isSelect={false}      
            onChange={(e)=>setSeminarName(e.target.value)}
            />
             <TextBox             
             label={"Add City"}
             id={"seminarCity"}
             name={"seminarCity"}
             autoFocus={false}                         
            value={seminarCity}  
            isSelect={false}      
            onChange={(e)=>setSeminarCity(e.target.value)}
            />
             <TextBox             
             label={"Add Address"}
             id={"seminarAddress"}
             name={"seminarAddress"}
             autoFocus={false}                         
            value={seminarAddress}  
            isSelect={false}      
            onChange={(e)=>setSeminarAddress(e.target.value)}
            />
             {/* <TextBox             
             label={"Add Seminar Date"}
             id={"seminarDate"}
             name={"seminarDate"}
             autoFocus={false}                         
            value={seminarDate}  
            isSelect={false}      
            onChange={(e)=>setSeminarDate(e.target.value)}
            /> */}
              <TextBox 
            id={"seminarDate"} 
            label={"Seminar Date*"} 
            name={"seminarDate"} 
            autoFocus={false}
            value={seminarDate}
            onChange={(e) => handleDate(e, setSeminarDate)}
            onKeyPress={(e)=>handleKeyDownDate(e,setSeminarDate)}
           
            />
               <TextBox             
             label={"Add Last Date For Booking"}
             id={"lastDateForBooking"}
             name={"lastDateForBooking"}
             autoFocus={false}                         
            value={lastDateForBooking}  
           
            onChange={(e) => handleDate(e, setLastDateForBooking)} 
            onKeyPress={(e)=>handleKeyDownDate(e,setLastDateForBooking)}  
           
            />
              <TextBox             
             label={"total seates"}
             id={"totalSeates"}
             name={"totalSeates"}
             autoFocus={false}                         
            value={totalSeates}  
            isSelect={false}      
            onChange={(e)=>setTotalSeates(e.target.value)}
            />
             <SubmitButton 
              caption={isupdate? "update": "Save"} 
             //isLoading={isLoading}
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
            
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                             <DataTable 
                  columns={columns}
                  Data={!isLoading? allSeminars:[]}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  
                  />
                         </Paper>
                  </Grid>

         </Grid>
      </Container>

            </>
        )
    }
  return (
   <AdminTemplate>
    {liveSeminarScreen()}
    </AdminTemplate>
  )
}

export default LiveSeminar