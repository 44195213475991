import { api } from '../../api'

import createSeminar from './createSeminar'
import deleteSeminar from './deleteSeminar'

import getAllSeminars from './getAllSeminars'
import updateSeminar from './updateSeminar'

export const liveSeminarsApi = api.injectEndpoints({
    endpoints: build => ({
        CreateLiveSeminar: createSeminar(build) , 
        GetAllLiveSeminars:getAllSeminars(build) ,
        UpdateLiveSeminars:updateSeminar(build) ,
        DeleteLiveSeminars:deleteSeminar(build) ,
      
    }),
    overrideExisting: true,
})
export const {
   
      useCreateLiveSeminarMutation,
      useGetAllLiveSeminarsQuery,
      useUpdateLiveSeminarsMutation,
      useDeleteLiveSeminarsMutation
   
     
     } = liveSeminarsApi
