import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
//import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, Collapse, List, MenuItem, Typography } from '@mui/material';
//import AssignmentIcon from '@mui/icons-material/Assignment';

export const superAdminItems = 
(  
  <React.Fragment>
     <Link to='/'
     style={{textDecoration:'none'}}
     >
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText 
      primary="Dashboard"
      
       />
    </ListItemButton>
    </Link>
    <Link 
    to='/course'
    style={{textDecoration:'none'}}
    >
    <ListItemButton>
      <ListItemIcon>
      <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Recorded Courses" />
     
    </ListItemButton>
    </Link>
    {/* <Link 
    to='/liveclasses'
    style={{textDecoration:'none'}}
    >
    <ListItemButton>
      <ListItemIcon>
      <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Live Classes" />
     
    </ListItemButton>
    </Link> */}
    {/* <Link
     to='/newadmission' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="New Admissions" />
    </ListItemButton>
    </Link> */}
    <Link
     to='/studentadmission' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Student Admissions" />
    </ListItemButton>
    </Link>
     {/* <Link to='/notes' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Notes" />
    </ListItemButton>
    </Link>
    <Link to='/books' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Book" />
    </ListItemButton>
    </Link> */}
    <Link to='/testimonials' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Create Testimonials" />
    </ListItemButton>
    </Link>
    <Link to='/quotes' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Add Quotes" />
    </ListItemButton>
    </Link>
    <Link to='/blogs' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Add Blogs" />
    </ListItemButton>
    </Link>
    <Link to='/youtubeinfo' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Add Youtube Details" />
    </ListItemButton>
    </Link>
    <Link to='/podcast' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Add Podcast" />
    </ListItemButton>
    </Link>
    <Link to='/mobileappdashboard' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Mobile App" />
    </ListItemButton>
    </Link>
    <Link to='/liveSeminarCreate' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Create Live Seminar" />
    </ListItemButton>
    </Link>
    <Link to='/reportdashboard' 
    style={{textDecoration:'none'}}>
    <ListItemButton>
    <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>  
      <ListItemText primary="Reports" />        
    </ListItemButton> 
    </Link>
  </React.Fragment>
)

