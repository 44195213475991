export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/liveSeminar/updateLiveSeminar',
                method: 'POST',
                body: payload,
               
            };
        },
       // invalidatesTags: ['allEnrolledStudents'],
       invalidatesTags: ['allLiveSeminars'],

    })
