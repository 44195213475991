
export const formatDateString = (inputDate) => {
    // Remove any existing slashes from the input
    const dateWithoutSlashes = inputDate.replace(/\//g, '');

    // Restrict the input to a maximum length of 8 characters
    const maxLengthInput = dateWithoutSlashes.slice(0, 8);

    // Only allow numbers
    const validInput = maxLengthInput.replace(/\D/g, '');

    // Split the input into day, month, and year parts
    const day = validInput.slice(0, 2);
    const month = validInput.slice(2, 4);
    const year = validInput.slice(4, 8);

    // Construct the formatted date with slashes at the correct positions
    let formattedDate = '';
    if (day.length > 0) {
      formattedDate += day;
      if (day.length === 2) {
        formattedDate += '/';
      }
    }
    if (month.length > 0) {
      formattedDate += month;
      if (month.length === 2) {
        formattedDate += '/';
      }
    }
    if (year.length > 0) {
      formattedDate += year;
    }

    return formattedDate;
  };

  export const handleKeyDownDate = (e,setSeminarDate) => {
   
    if (e.key === 'Backspace') {
      var value=e.target.value
     const formattedDate = value.replace(/\//g, '');
    // return formattedDate
     setSeminarDate(formattedDate)
     // const formattedDate = Staff.birthdate.replace(/\//g, '');

    //   setStudent({
    //     ...Student,
    //     [e.target.name]: formattedDate
    //    // birthdate: formattedDate
    //   });
     
    }
  };